import { ref, getDatabase } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { app } from "../firebase/firebase";
import { useState } from "react";
import { Entry } from "../Admin/AdminContentPage";

const database = getDatabase(app);

type UseFirebaseContent = {
  content: Record<string, Entry[]> | undefined;
};

const useFirebaseContent = (): UseFirebaseContent => {
  const [snapshots, loading] = useList(ref(database, "content"));
  const [content, setContent] = useState<Record<string, Entry[]> | undefined>();

  if (
    snapshots !== undefined &&
    snapshots.length > 0 &&
    content === undefined &&
    !loading
  ) {
    setContent(snapshots[0].val());

    const newContent = {};

    snapshots.forEach((snapshot) => {
      const dataSnapshot = snapshot;
      if (!dataSnapshot.key) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newContent[dataSnapshot.key] = dataSnapshot.val();
    });

    setContent(newContent);
  }

  return {
    content: content,
  };
};

export default useFirebaseContent;
