import * as React from "react";
import { Box, Modal } from "@mui/material";
import { Entry } from "./AdminContentPage";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./Admin.module.scss";
import { ModalStyle } from "../ContentPage/ContentElement";
import AdminEditForm from "./AdminEditForm";
interface AdminContentElementProps {
  entry: Entry;
  tabTitle: string;
}

export default function AdminContentElement({
  entry,
  tabTitle,
}: AdminContentElementProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className={styles.contentElementWrapper} onClick={handleOpen}>
        {entry.title} <EditIcon />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.adminModal}
      >
        <Box
          sx={{
            ...ModalStyle,
            height: "100%",
            width: "90%",
          }}
          className={"modal-container"}
        >
          <AdminEditForm
            passedEntry={entry}
            tabTitle={tabTitle}
            closeTab={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}
