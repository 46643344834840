import * as React from "react";
import {
  Box,
  ButtonBase,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./ContentPage.scss";
import GavelIcon from "@mui/icons-material/Gavel";
import ChatBubbleOutlineTwoToneIcon from "@mui/icons-material/ChatBubbleOutlineTwoTone";
import { Entry } from "../Admin/AdminContentPage";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 300,
    width: "100%",
    position: "relative",
  },
  image: {
    position: "relative",
    height: 200,
    backgroundColor: "black",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  recommended: {
    position: "absolute",
    right: "8px",
    bottom: "8px",
    zIndex: 2,
    pointerEvents: "none",
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  musicImageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

interface ContentElementProps {
  entry: Entry;
  tabTitle: string;
}

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "white",
  maxHeight: "80%",
  backgroundColor: "#444444",
};

export default function ContentElement({
  entry,
  tabTitle,
}: ContentElementProps) {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div
        className={classes.root}
        style={{
          gridColumnEnd: !isDesktop ? "" : `span ${entry.size}`,
        }}
        onClick={() => {
          handleOpen();
        }}
      >
        <ButtonBase
          focusRipple
          key={entry.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: "100%",
          }}
        >
          <span
            className={
              tabTitle === "music" ? classes.musicImageSrc : classes.imageSrc
            }
            style={{
              backgroundImage: `url(${entry.url})`,
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {entry.title}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className={"modal-container"}>
          <h3>
            <a
              href={`https://www.google.com/search?q=${entry.title}`}
              target="_blank"
              style={{ color: "white" }}
            >
              {entry.title}
            </a>
          </h3>
          {entry.comments && (
            <ul style={{ padding: 0 }}>
              {entry.comments.map((comment) => (
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderBottom: "1px solid grey",
                  }}
                  key={comment.comment}
                >
                  <ChatBubbleOutlineTwoToneIcon sx={{ marginRight: 1 }} />
                  <span>{comment.comment}</span>
                </li>
              ))}
            </ul>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GavelIcon sx={{ marginRight: 2 }} />
            <p>{entry.rating}</p>
          </div>
          <p style={{ textAlign: "right", fontSize: 11 }}>
            written on {new Date(entry.createdAt).toLocaleDateString("uk-Uk")}
          </p>
        </Box>
      </Modal>
    </>
  );
}
