import { Entry } from "./AdminContentPage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import styles from "./Admin.module.scss";
import { getDatabase, ref, set } from "firebase/database";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

const AdminNewForm = ({
  tabTitle,
  currentAmountOfEntries,
  closeTab,
}: {
  tabTitle: string;
  currentAmountOfEntries: number;
  closeTab: () => void;
}) => {
  const [entry, setEntry] = useState<Entry>({
    createdAt: "",
    comments: [],
    id: 0,
    rating: "",
    recommended: false,
    size: 0,
    title: "",
    updatedAt: "",
    url: "",
  });

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = async (data: any) => {
    const file = data.url?.[0];

    let downloadUrl = "";
    if (file) {
      try {
        const storageImageUrl = `${data.title}/${file.name}`;
        const storage = getStorage();
        const imgRef = storageRef(storage, storageImageUrl);

        await uploadBytes(imgRef, file);
        downloadUrl = await getDownloadURL(imgRef);
      } catch (err) {
        console.error("err in img-stuff");
        console.error(err);
      }
    }

    const toSubmit = {
      ...data,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      comments:
        data?.comments
          ?.split("\n")
          .map((comment: string) => ({ comment: comment })) || [],
      id: currentAmountOfEntries,
    };

    if (downloadUrl !== "") {
      toSubmit.url = downloadUrl;
    }

    const db = getDatabase();
    set(ref(db, `content/${tabTitle}/${currentAmountOfEntries}`), toSubmit);
    closeTab();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h3>Adding new entry for {tabTitle}</h3>
      <span>title</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.title} {...register("title")} />
      </div>
      <span>url</span>
      <div className={styles.editFormDiv}>
        <input type="file" {...register("url")} />
      </div>
      <span>size</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.size} {...register("size")} />
      </div>

      <span>comments</span>

      <textarea
        defaultValue={entry.comments
          .map((comment) => comment.comment)
          .join("\n")}
        {...register(`comments`)}
        rows={entry.comments.length}
        style={{ width: "100%" }}
      />

      <span>Rating</span>

      <div className={styles.editFormDiv}>
        <input defaultValue={entry.rating} {...register("rating")} />
      </div>

      <button type="submit">Create!</button>
    </form>
  );
};

export default AdminNewForm;
