import * as React from "react";
import styles from "./Admin.module.scss";
import useFirebaseLogin from "../hooks/useFirebaseLogin";
import { Button, CircularProgress } from "@mui/material";
import AdminDashboard from "./AdminDashboard";
import useFirebaseContent from "../hooks/useFirebaseContent";

function Admin() {
  const { attemptLogin, isFabian, loading } = useFirebaseLogin();
  const { content } = useFirebaseContent();

  return (
    <div className={styles.root}>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {!isFabian && (
        <div className={styles.loading}>
          <Button variant="contained" onClick={attemptLogin}>
            Login
          </Button>
        </div>
      )}
      {isFabian && content && <AdminDashboard content={content} />}
    </div>
  );
}

export default Admin;
