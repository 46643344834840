import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Main/Main";
import Admin from "./Admin";

function Router() {
  return (
    <Routes>
      <Route path="/admin" element={<Admin />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
}

export default Router;
