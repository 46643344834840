import * as React from "react";
import "./ContentPage.scss";
import ContentElement from "./ContentElement";
import { Entry } from "../Admin/AdminContentPage";

export type ContentPageProps = {
  entries: Entry[];
  tabTitle: string;
};

function ContentPage({ entries, tabTitle }: ContentPageProps) {
  return (
    <div className={"wrapper"}>
      {[...entries].reverse().map((element) => (
        <ContentElement
          entry={element}
          key={element.title + element.rating}
          tabTitle={tabTitle}
        />
      ))}
    </div>
  );
}

export default ContentPage;
