import React from "react";
import "../App.scss";
import { CircularProgress } from "@mui/material";
import useFirebaseContent from "../hooks/useFirebaseContent";
import ContentNav from "../ContentNav";

function Main() {
  const { content } = useFirebaseContent();

  return (
    <>
      {content === undefined && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      {content !== undefined && <ContentNav content={content} />}
    </>
  );
}

export default Main;
