import * as React from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import ContentPage, { ContentPageProps } from "../ContentPage/ContentPage";
import { ReactElement, useMemo } from "react";
import { Entry } from "../Admin/AdminContentPage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography component="span">{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type MainPageProps = {
  content: Record<string, Entry[]>;
};

export const ContentTabs = ({
  content,
  tabTitles,
  ChildComponent,
}: {
  content: Record<string, Entry[] | string>;
  tabTitles: string[];
  ChildComponent: ({ entries, tabTitle }: ContentPageProps) => ReactElement;
}) => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div>
      <Tabs value={tab} onChange={handleChange}>
        {tabTitles.map((title, idx) => (
          <Tab label={title} {...a11yProps(idx)} key={title} />
        ))}
      </Tabs>
      {tabTitles.map((title, idx) => (
        <TabPanel value={tab} index={idx} key={title}>
          <ChildComponent
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            entries={typeof content[title] === "string" ? [] : content[title]}
            tabTitle={title}
          />
        </TabPanel>
      ))}
    </div>
  );
};

function ContentNav({ content }: MainPageProps) {
  const tabTitles = useMemo(() => Object.keys(content), []);

  return (
    <div className="App">
      <ContentTabs
        content={content}
        tabTitles={tabTitles}
        ChildComponent={ContentPage}
      />
    </div>
  );
}

export default ContentNav;
