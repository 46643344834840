import * as React from "react";
import { ContentTabs } from "../ContentNav/ContentNav";
import AdminContentPage, { Entry } from "./AdminContentPage";
import { useMemo } from "react";

const AdminDashboard = ({
  content,
}: {
  content: Record<string, Entry[] | string>; // todo: string here is a dirty hack so i can just create new categories in the firebase-ui. change this to something more sustantial later and then remove the string here and everywhere else.
}) => {
  const tabTitles = useMemo(() => Object.keys(content), []);

  return (
    <div>
      <ContentTabs
        content={content}
        tabTitles={tabTitles}
        ChildComponent={AdminContentPage}
      />
    </div>
  );
};

export default AdminDashboard;
