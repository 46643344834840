import { Entry } from "./AdminContentPage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import styles from "./Admin.module.scss";
import { getDatabase, ref as dbRef, set } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const AdminEditForm = ({
  passedEntry,
  tabTitle,
  closeTab,
}: {
  passedEntry: Entry;
  tabTitle: string;
  closeTab: () => void;
}) => {
  const [entry, setEntry] = useState<undefined | Entry>();

  useEffect(() => {
    setEntry(passedEntry);
  }, []);

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = async (data: any) => {
    const file = data.url?.[0];

    let downloadUrl = "";
    if (file) {
      try {
        console.log("filename is", file);
        const storageImageUrl = `${data.title.replace(/[^\w\s]/gi, "")}/${
          file.name
        }`;
        const storage = getStorage();
        const imgRef = storageRef(storage, storageImageUrl);

        await uploadBytes(imgRef, file);
        downloadUrl = await getDownloadURL(imgRef);
      } catch (err) {
        console.error("err in img-stuff");
        console.error(err);
      }
    }

    const toSubmit = {
      ...passedEntry,
      ...data,
      url: passedEntry.url, // spaghetti code here
      comments: data.comments
        .split("\n")
        .map((comment: string) => ({ comment: comment })),
    };
    if (file !== undefined && downloadUrl !== "") {
      toSubmit.url = downloadUrl;
    }

    const db = getDatabase();
    set(dbRef(db, `content/${tabTitle}/${toSubmit.id}`), toSubmit);

    closeTab();
  };

  if (!entry) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h3>
        Editing {tabTitle} {entry.title}
      </h3>
      <span>title</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.title} {...register("title")} />
      </div>
      <span>url</span>
      <div className={styles.editFormDiv}>
        <input type="file" {...register("url")} />
      </div>
      <span>size</span>
      <div className={styles.editFormDiv}>
        <input defaultValue={entry.size} {...register("size")} />
      </div>

      <span>comments</span>
      <div className={styles.editFormDiv}>
        <textarea
          defaultValue={entry.comments
            .map((comment) => comment.comment)
            .join("\n")}
          {...register(`comments`)}
          rows={entry.comments.length}
          style={{ width: "100%" }}
        />
      </div>

      <span>Rating</span>

      <div className={styles.editFormDiv}>
        <input defaultValue={entry.rating} {...register("rating")} />
      </div>

      <button type="submit">Update!</button>
    </form>
  );
};

export default AdminEditForm;
